<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <b>
                  <p class="mb-0 ml-2" style="font-size: 18px">
                    SOLICITUD CARGA SECA POR LLAMADO DE ACTIVOS DE PRODUCCIÓN
                    <span
                      class="badge"
                      :class="
                        form.estado == 1
                          ? 'badge-info'
                          : form.estado == 2
                          ? 'badge-success'
                          : form.estado == 3
                          ? 'badge-danger'
                          : form.estado == 4
                          ? 'badge-primary'
                          : form.estado == 5
                          ? 'bg-warning'
                          : ''
                      "
                    >
                      {{ form.nEstado }}
                    </span>
                  </p>
                </b>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga seca</li>
                  <li class="breadcrumb-item active">Internas</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario {{ accion }} Solicitudes
              </legend>
              <div class="row">
                <div class="col-sm-9">
                  <div class="row">
                    <div class="form-group col-md-3">
                      <label for="fecha">Fecha requerimiento solicitud</label>
                      <input
                        type="date"
                        :min="hoy"
                        class="form-control form-control-sm"
                        id="fecha"
                        v-model="form.fecha"
                        v-on:input="$v.form.fecha.$touch"
                        :class="
                          $v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                        @change="validaFecha()"
                      />
                      <div
                        v-if="
                          (!$v.form.fecha.$dirty || $v.form.fecha.$invalid) &&
                            form.fecha == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label>Bloque</label>
                      <v-select
                        v-model="form.bloque"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.bloques"
                        v-on:input="$v.form.bloque_id.$touch"
                        @input="seleccionarBloque()"
                        class="form-control form-control-sm p-0"
                        :class="
                          $v.form.bloque_id.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      ></v-select>
                      <div
                        v-if="
                          (!$v.form.bloque_id.$dirty ||
                            $v.form.bloque_id.$invalid) &&
                            form.bloque_id == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label>Sitio</label>
                      <v-select
                        v-model="form.sitio"
                        placeholder="Nombre"
                        :disabled="
                          form.bloque_id == null ||
                            form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                        label="nombre"
                        :options="listasForms.sitios"
                        v-on:input="$v.form.sitio_id.$touch"
                        @input="seleccionarSitio()"
                        class="form-control form-control-sm p-0"
                        :class="
                          $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid'
                        "
                      ></v-select>
                      <div
                        v-if="
                          (!$v.form.sitio_id.$dirty ||
                            $v.form.sitio_id.$invalid) &&
                            form.sitio_id == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <label for="tipo_solicitud">Tipo Solicitud</label>
                      <select
                        id="tipo_solicitud"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.tipo_solicitud"
                        v-on:input="$v.form.tipo_solicitud.$touch"
                        :class="
                          $v.form.tipo_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="modalEmergencia()"
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                          :key="tipo_solicitud.numeracion"
                          :value="tipo_solicitud.numeracion"
                        >
                          {{ tipo_solicitud.descripcion }}
                        </option>
                      </select>
                      <div
                        v-if="
                          (!$v.form.tipo_solicitud.$dirty ||
                            $v.form.tipo_solicitud.$invalid) &&
                            form.tipo_solicitud == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-2">
                      <label for="clase_solicitud">Clase de Solicitud</label>
                      <select
                        id="clase_solicitud"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.clase_solicitud"
                        v-on:input="$v.form.clase_solicitud.$touch"
                        :class="
                          $v.form.clase_solicitud.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                        @change="validaFecha()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="clase_solicitud in listasForms.clases_solicitudes"
                          :key="clase_solicitud.numeracion"
                          :value="clase_solicitud.numeracion"
                        >
                          {{ clase_solicitud.descripcion }}
                        </option>
                      </select>
                      <div
                        v-if="
                          (!$v.form.clase_solicitud.$dirty ||
                            $v.form.clase_solicitud.$invalid) &&
                            form.clase_solicitud == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div
                      class="form-group col-md-2"
                      v-if="form.tipo_solicitud == 2"
                    >
                      <label for="tipo_emergencia">Tipo Emergencia</label>
                      <select
                        id="tipo_emergencia"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.tipo_emergencia"
                        v-on:input="$v.form.tipo_emergencia.$touch"
                        :class="
                          $v.form.tipo_emergencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="modalEmergencia2()"
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_emergencia in listasForms.tipos_emergencias"
                          :key="tipo_emergencia.numeracion"
                          :value="tipo_emergencia.numeracion"
                        >
                          {{ tipo_emergencia.descripcion }}
                        </option>
                      </select>
                      <div
                        v-if="
                          (!$v.form.tipo_emergencia.$dirty ||
                            $v.form.tipo_emergencia.$invalid) &&
                            form.tipo_emergencia == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="observaciones">Observaciones</label>
                      <textarea
                        class="form-control form-control-sm"
                        v-model="form.observaciones"
                        v-on:input="$v.form.observaciones.$touch"
                        :class="
                          $v.form.observaciones.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                      </textarea>
                      <div
                        v-if="
                          (!$v.form.observaciones.$dirty ||
                            $v.form.observaciones.$invalid) &&
                            form.observaciones == ''
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="justificacion">Justificación</label>
                      <textarea
                        class="form-control form-control-sm"
                        id="justificacion"
                        v-model="form.justificacion"
                        v-on:input="$v.form.justificacion.$touch"
                        :class="
                          $v.form.justificacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                      </textarea>
                      <div
                        v-if="
                          (!$v.form.justificacion.$dirty ||
                            $v.form.justificacion.$invalid) &&
                            form.justificacion == ''
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.tipo_emergencia == 2"
                    >
                      <label for="nombre_vice">Nombre Vicepresidente</label>
                      <select
                        id="nombre_vice"
                        class="form-control form-control-sm col-md-12"
                        v-model="form.viceprecidente_id"
                        v-on:input="$v.form.viceprecidente_id.$touch"
                        :class="
                          $v.form.viceprecidente_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="
                          form.estado == 2 ||
                            form.id ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="vicepreseidentes in listasForms.vicepreseidentes"
                          :key="vicepreseidentes.id"
                          :value="vicepreseidentes.id"
                        >
                          {{ vicepreseidentes.nombres }}
                        </option>
                      </select>
                      <div
                        v-if="
                          (!$v.form.viceprecidente_id.$dirty ||
                            $v.form.viceprecidente_id.$invalid) &&
                            form.viceprecidente_id == null
                        "
                      >
                        <span class="text-danger"
                          ><small>*Campo requerido</small></span
                        >
                      </div>
                    </div>

                    <div
                      class="form-group col-md-12"
                      v-if="form.tipo_emergencia"
                    >
                      <div class="row">
                        <div class="col-md-3"></div>
                        <div
                          class="col-md-6 text-center"
                          style="font-size: 12px"
                        >
                          <b>
                            "Su solicitud se gestionará en el menor tiempo
                            posible. Esto dependerá de la disponibilidad y
                            ubicación de los vehículos o equipos por parte de
                            los contratistas, los tiempos para este proceso
                            podran tomarse entre 6, 12, 18 ó 24 horas."
                          </b>
                        </div>
                        <div class="col-md-3"></div>
                      </div>

                      <div class="row p-5">
                        <div class="col-md-3"></div>
                        <div
                          class="col-md-6 text-center"
                          style="font-size: 12px"
                        >
                          <label for="archivo_emergencia"
                            >Cargar certificado de solicitud de emergencia
                          </label>
                        </div>
                        <div class="col-md-4"></div>
                        <div
                          class="justify-content-center"
                          v-if="form.archivo_emergencia == null"
                        >
                          <input
                            type="file"
                            id="btnFile"
                            ref="file"
                            class="form-control-file"
                            accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                            @change="elegirDirectorio()"
                            :disabled="
                              form.estado == 2 ||
                                form.id ||
                                form.estado == 3 ||
                                form.estado == 4
                            "
                          />
                        </div>
                        <div
                          v-else
                          style="display: flex; justify-content: center"
                        >
                          <button
                            type="button"
                            class="btn btn-info"
                            @click="getArchivo(form.archivo_emergencia)"
                            v-show="form.archivo_emergencia !== null"
                            :disabled="
                              form.estado == 2 ||
                                form.estado == 3 ||
                                form.estado == 4
                            "
                          >
                            <i class="fas fa-file-download"></i>
                          </button>
                        </div>
                        <div
                          class="col-md-2"
                          v-if="form.get_archivo && !form.id"
                        >
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            @click="eliminarAdj()"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-3">
                      <button
                        v-if="form.tipo_solicitud == 2"
                        class="btn bg-primary"
                        @click="saveDocumento()"
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button
                        v-else
                        class="btn bg-primary"
                        v-show="!$v.form.$invalid"
                        @click="save(1)"
                        :disabled="
                          form.estado == 2 ||
                            form.estado == 3 ||
                            form.estado == 4
                        "
                      >
                        <i class="fas fa-paper-plane"></i><br />Guardar
                      </button>
                      <button
                        class="btn bg-secondary ml-1"
                        @click="validacionVolver()"
                      >
                        <i class="fas fa-reply"></i><br />Volver
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card card-outline card-navy col-md-12 m-2 p-2">
                    <div class="card-header pt-2 pb-2">
                      <h3 class="card-title">
                        <h6>
                          <i class="fas fa-users"></i>
                          Datos de Usuario
                        </h6>
                      </h3>
                    </div>
                    <div>
                      <div class="form-group">
                        <label for="user">Usuario</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="user"
                          v-model="form.user.name"
                          :class="
                            $v.form.user.$invalid ? 'is-invalid' : 'is-valid'
                          "
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="correo">Correo</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="correo"
                          v-model="form.user.email"
                          disabled
                        />
                      </div>
                      <div class="form-group">
                        <label for="celular">Celular</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="celular"
                          v-model="form.user.cel"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <!-- Rutas -->
            <fieldset
              v-show="
                form.id != null &&
                  ((form.clase_solicitud == 1 && !userRol) ||
                    form.clase_solicitud == 2)
              "
              class="well card"
            >
              <legend class="well-legend text-bold bg-frontera text-light">
                Ruta
              </legend>
              <CsSolicitudesInternasRutas ref="CsSolicitudesInternasRutas" />
            </fieldset>

            <!-- DETALLE -->
            <div
              v-if="$refs.CsSolicitudesInternasRutas"
              v-show="
                form.id != null &&
                  ($refs.CsSolicitudesInternasRutas.rutas.length > 0 || userRol)
              "
            >
              <fieldset class="well card">
                <legend class="well-legend text-bold bg-frontera text-light">
                  Detalle Solicitud
                </legend>
                <CsSolicitudesInternasDetSolicitud
                  ref="CsSolicitudesInternasDetSolicitud"
                />
              </fieldset>
            </div>

            <!-- PRESUPUESTO -->
            <div v-if="$refs.CsSolicitudesInternasDetSolicitud">
              <fieldset
                v-show="
                  $refs.CsSolicitudesInternasDetSolicitud.detSolicitudes
                    .length > 0 &&
                    ((form.clase_solicitud == 1 && !userRol) ||
                      form.clase_solicitud == 2)
                "
                class="well card"
              >
                <legend class="well-legend text-bold bg-frontera text-light">
                  Cargos Contables
                </legend>
                <CsSolicitudesInternasPresupuesto
                  ref="CsSolicitudesInternasPresupuesto"
                />
              </fieldset>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="form-group">
              <button class="btn btn-default ml-1" @click="validacionVolver()">
                <i class="fas fa-reply"></i>
                <div>Volver</div>
              </button>
              <button
                class="btn bg-frontera text-white"
                v-if="
                  form.id &&
                    ((form.clase_solicitud == 2 &&
                      $refs.CsSolicitudesInternasRutas.ruta_descargue == 1 &&
                      $refs.CsSolicitudesInternasRutas.ruta_cargue == 1) ||
                      form.clase_solicitud == 1)
                "
                @click="saveTotal()"
                :disabled="
                  form.estado == 2 ||
                    form.estado == 3 ||
                    form.estado == 4 ||
                    btnDisabled
                "
              >
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top=""
                  title="La solicitud se encuentra autorizada"
                  v-if="form.estado == 2"
                ></i>
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top=""
                  title="La solicitud se encuentra rechazada"
                  v-else-if="form.estado == 3"
                ></i>
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top=""
                  title="Ya se encuentran vehiculos postulados para esta solicitud"
                  v-else-if="form.estado == 4"
                ></i>
                <i class="fas fa-paper-plane" v-else></i>
                <div>Enviar</div>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
import CsSolicitudesInternasRutas from "./CsSolicitudesInternasRutas";
import CsSolicitudesInternasDetSolicitud from "./CsSolicitudesInternasDetSolicitud";
import CsSolicitudesInternasPresupuesto from "./CsSolicitudesInternasPresupuesto";
import vSelect from "vue-select";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "SolicitudesInternasForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    vSelect,
    CsSolicitudesInternasRutas,
    CsSolicitudesInternasDetSolicitud,
    CsSolicitudesInternasPresupuesto,
  },

  data() {
    return {
      cargando: false,
      user: {},
      file: null,
      accion: "",
      act: "",
      metodo: "",
      userRol: false,
      hoy: moment().format("YYYY-MM-DD"),
      btnDisabled: false,
      form: {
        id: null,
        user_id: null,
        user: {},
        tipo_solicitud: null,
        clase_solicitud: null,
        tipo_emergencia: null,
        viceprecidente_id: null,
        bloque: null,
        sitio: null,
        sitio_id: null,
        bloque_id: null,
        fecha: null,
        observaciones: null,
        justificacion: null,
        gerente_area_id: null,
        archivo_emergencia: null,
        get_archivo: null,
        estado: null,
        nEstado: null,
        presupuesto_verificado: null,
      },
      listasForms: {
        tipos_solicitudes: [],
        clases_solicitudes: [],
        tipos_emergencias: [],
        vicepreseidentes: [],
        sitios: [],
        bloques: [],
      },
      guardar: 0,
      message: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    let form = {};
    // Cuando es emergencia
    if (this.form.tipo_solicitud == 2) {
      // Si es emergencia extemporanea
      if (this.form.tipo_emergencia == 2) {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          bloque_id: {
            required,
          },
          sitio_id: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          clase_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          viceprecidente_id: {
            required,
          },
          get_archivo: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      } else {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          bloque_id: {
            required,
          },
          sitio_id: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          clase_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      }
    } else {
      // Cuando no es emergencia
      form = {
        user: {
          required,
        },
        fecha: {
          required,
        },
        bloque_id: {
          required,
        },
        sitio_id: {
          required,
        },
        tipo_solicitud: {
          required,
        },
        clase_solicitud: {
          required,
        },
        observaciones: {
          required,
        },
        justificacion: {
          required,
        },
      };
    }

    return {
      form: form,
    };
  },
  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.act = this.$route.params.act;
      if (this.$route.params.accion == "Editar") {
        this.metodo = "PUT";
        this.form = await this.$route.params.data_edit;
        this.form.user_id = await this.$route.params.data_edit.user.id;
        this.form.get_archivo = (await this.form.archivo_emergencia)
          ? true
          : null;
      } else {
        this.metodo = "POST";
        this.form.user = await this.$store.state.user;
        this.form.user_id = await this.$store.state.user.id;
      }
      await this.getUser();
      await this.userRoles();
      await this.$refs.CsSolicitudesInternasRutas.getRutas();
      await this.$refs.CsSolicitudesInternasDetSolicitud.getDetSolicitudes();
      await this.$refs.CsSolicitudesInternasPresupuesto.getCsContable();
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    modalEmergencia() {
      this.form.tipo_emergencia = null;
      this.eliminarAdj();
      if (this.form.tipo_solicitud == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Recuerde, si selecciona Emergencia Operacional, la Solicitud se enviará a la <strong>Vicepresidencia de Operaciones</strong>.</h4>
          <p style= "color: red">*Nota: Por favor adjuntar la declaración y aprobación de la emergencia de su vicepresidente si cuenta con ella, de lo contrario su solicitud continuará el curso de aprobación por este medio (kissflow).</p>
          <p>Recuerde que si cancela su solicitud por cualquier motivo se deberá informar inmediatamente, de no hacerlo, esto le generará un costo adicional debido al alistamiento y posibles movilizaciones de los equipos.</p>
          `,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
      this.validaFecha();
    },

    modalEmergencia2() {
      if (this.form.tipo_emergencia == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Es obligatorio adjuntar el soporte de aprobación (Correo, Foto, Whatsapp y etc) por el vp correspondiente.</h4>`,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
    },

    validaFecha() {
      if (this.form.tipo_solicitud == 1 && this.form.fecha) {
        let fecha_hoy = moment(this.hoy);
        let fecha_solicitud = moment(this.form.fecha);
        if (
          fecha_solicitud.diff(fecha_hoy, "days") < 2 &&
          this.form.clase_solicitud == 2
        ) {
          this.form.fecha = null;
          this.$swal({
            icon: "error",
            title:
              "Las Solicitudes Normales deben hacerce con más de un 48 horas de antelación.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    async getTipoClase() {
      await axios.get("/api/lista/183").then((response) => {
        this.listasForms.clases_solicitudes = response.data;
      });
    },

    async getTipoEmergencia() {
      await axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    getVicepresidente() {
      const in_id = [163, 245];
      const params = {
        in_id,
      };
      axios
        .get("/api/admin/funcionarios/lista", {
          params,
        })
        .then((response) => {
          this.listasForms.vicepreseidentes = response.data;
        });
    },

    async save(enviar) {
      this.form.accion = this.accion;
      if (this.form.id !== null && this.form.id !== undefined && enviar == 2) {
        // Se valida si la solicitud es emergencia la solicitud queda automáticamente autorizada
        if (
          (this.form.tipo_solicitud == 2 && this.form.tipo_emergencia == 2) ||
          (this.form.tipo_solicitud == 2 &&
            this.form.tipo_emergencia == 1 &&
            this.form.get_archivo)
        ) {
          this.form.estado = 2;
        }
        // Se valida si la clase solicitud es fijo la solicitud queda automáticamente autorizada
        else if (this.form.clase_solicitud == 1 && this.userRol) {
          if (
            this.$refs.CsSolicitudesInternasDetSolicitud.detSolicitudes.length >
            0
          ) {
            this.form.estado = 2;
          } else {
            this.cargando = false;
            await this.$swal({
              icon: "warning",
              title: "Advertencia!!",
              text:
                "No ha ingresado los datos del Detalle de la solicitud. Por favor validar ",
              timer: 3000,
              backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
              timerProgressBar: true,
              confirmButtonText: "Aceptar",
            });
            return false;
          }
        } else {
          this.form.estado = 1;
        }
      } else {
        if (this.form.clase_solicitud == 1 && this.userRol) {
          this.form.presupuesto_verificado = 1;
        }
        this.form.estado = 5;
      }

      if (!this.$v.form.$invalid) {
        this.cargando = true;
        this.form.enviar = enviar;
        await axios({
          method: this.metodo,
          url: "/api/funcionariosFrontera/solicitudesInternas",
          data: this.form,
        })
          .then((response) => {
            this.form.id = response.data.id;
            this.form.nEstado = response.data.nEstado;
            this.$swal({
              text: "Se guardó la solicitud Interna...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async saveDocumento() {
      this.cargando = true;
      var vm = this;
      let formData = new FormData();
      if (this.form.tipo_emergencia == 2) {
        if (this.form.get_archivo === null) {
          this.cargando = false;
          await this.$swal({
            icon: "warning",
            title: "Advertencia!!",
            text: "No ha ingresado el adjunto de soporte. Por favor validar ",
            timer: 3000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            timerProgressBar: true,
            confirmButtonText: "Aceptar",
          });
          return false;
        }
      }

      if (this.$v.form.$invalid) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text: "No ha ingresado todos lo campos. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.form.id !== null && this.form.id !== undefined) {
        formData.append("id", this.form.id);
        formData.append("estado", 1);
      } else {
        formData.append("estado", 5);
      }
      formData.append("archivo_emergencia", vm.file);
      formData.append("user_id", vm.form.user_id);
      formData.append("tipo_solicitud", vm.form.tipo_solicitud);
      formData.append("clase_solicitud", vm.form.clase_solicitud);
      formData.append("tipo_emergencia", vm.form.tipo_emergencia);
      formData.append("sitio_id", vm.form.sitio.id);
      formData.append("bloque_id", vm.form.bloque.id);
      formData.append("fecha", vm.form.fecha);
      formData.append("justificacion", vm.form.justificacion);
      formData.append("observaciones", vm.form.observaciones);

      await axios
        .post(
          "/api/funcionariosFrontera/solicitudesInternas/archivo_emergencia",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.form.id = response.data.id;
          this.getIndex();
          this.cargando = false;
          this.$swal({
            text: "Se guardó exitosamente...",
            icon: "success",
            confirmButtonText: "Ok!",
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async saveTotal() {
      this.cargando = true;
      if (
        this.$refs.CsSolicitudesInternasDetSolicitud.detSolicitudes.length == 0
      ) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha ingresado los datos del Detalle de la solicitud. Por favor validar ",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          confirmButtonText: "Aceptar",
        });
        return false;
      }

      if (
        (this.form.clase_solicitud == 1 && !this.userRol) ||
        this.form.clase_solicitud == 2
      ) {
        if (this.$refs.CsSolicitudesInternasRutas.rutas.length == 0) {
          this.cargando = false;
          await this.$swal({
            icon: "warning",
            title: "Advertencia!!",
            text:
              "No ha ingresado los datos de la Ruta para esta solicitud. Por favor validar ",
            timer: 3000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            timerProgressBar: true,
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (
          this.$refs.CsSolicitudesInternasPresupuesto.csContables.length == 0
        ) {
          this.cargando = false;
          await this.$swal({
            icon: "warning",
            title: "Advertencia!!",
            text:
              "No ha ingresado los datos de Cargos Contables para esta solicitud. Por favor validar.",
            timer: 3000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            timerProgressBar: true,
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (
          this.$refs.CsSolicitudesInternasPresupuesto.totalPresupuesto < 100 ||
          this.$refs.CsSolicitudesInternasPresupuesto.totalPresupuesto > 100
        ) {
          this.cargando = false;
          await this.$swal({
            icon: "warning",
            title: "Advertencia!!",
            text:
              "El porcentaje total para Cecos y Afes debe ser del 100%. Por favor validar porcentaje actual " +
              this.$refs.CsSolicitudesInternasPresupuesto.totalPresupuesto,
            timer: 3000,
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            timerProgressBar: true,
            confirmButtonText: "Aceptar",
          });
          return false;
        }
      }

      this.btnDisabled = true;
      if (this.form.clase_solicitud == 1 && this.userRol) {
        this.form.presupuesto_verificado = 1;
      }
      await this.$refs.CsSolicitudesInternasDetSolicitud.saveDetSolicitudTotal();
      await this.save(2);
      if (
        (this.form.clase_solicitud == 1 && !this.userRol) ||
        this.form.clase_solicitud == 2
      ) {
        await this.$refs.CsSolicitudesInternasRutas.saveRutaTotal();
        await this.$refs.CsSolicitudesInternasPresupuesto.saveContableTotal();
      }
      //await this.back();
    },

    async getSitios() {
      await axios
        .get("/api/admin/sitios/lista", {
          params: { bloque_id: this.form.bloque_id },
        })
        .then((response) => {
          this.listasForms.sitios = response.data;
        });
    },

    seleccionarSitio() {
      if (this.form.sitio) {
        this.form.sitio_id = this.form.sitio.id;
      } else {
        this.form.sitio_id = null;
      }
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    seleccionarBloque() {
      if (this.form.bloque) {
        this.form.bloque_id = this.form.bloque.id;
        this.getSitios();
      } else {
        this.form.bloque_id = null;
      }
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = (await this.user.funcionario[0])
        ? this.user.funcionario[0].nArea
        : "";
    },

    validacionVolver() {
      this.$swal({
        title: "¿Está seguro de realizar esta acción?",
        text:
          "Por favor validar nuevamente si desea salir de esta pantalla, ya que en caso de aceptar perdera todo lo anteriormente diligenciado. Le recomendamos verificar si desea salir realmente de esta pantalla! En caso de que si oprima aceptar, en caso de que no oprima cancelar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar!",
      }).then((result) => {
        if (result.value) {
          return this.$router.replace("/CargaSeca/SolicitudesInternas");
        }
      });
    },

    elegirDirectorio(e) {
      this.file = this.$refs.file.files[0];
      this.form.get_archivo = true;
    },

    eliminarAdj() {
      this.file = null;
      this.form.archivo_emergencia = null;
      this.form.get_archivo = null;
      if (this.form.get_archivo != null) {
        document.getElementById("btnFile").value = "";
      }
    },

    getArchivo(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    userRoles() {
      this.userRol = false;
      let usuario = this.$store.state.user;
      for (let i = 0; i < usuario.roles.length; i++) {
        let roles = usuario.roles[i];
        switch (roles.id) {
          case 44:
            this.userRol = true;
            break;
          default:
            break;
        }
      }
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getTipoSolicitud();
    await this.getTipoClase();
    await this.getTipoEmergencia();
    await this.getVicepresidente();
    await this.getBloques();
    await this.getIndex();
    this.cargando = false;
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
